




































import {Component, Vue} from 'vue-property-decorator';
import {CuadrillaHorario,RegistrarHorario} from '@/entidades/Plantacion/CuadrillaHorario';
import {Action, Mutation, State} from 'vuex-class';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
@Component({
    name : 'AsistenciaCuadrillaView',
    components:{
        'tabla' : () => import('@/components/General/TablaGeneralFinal.vue'),
        'horarioAdd' : () => import('@/components/Forestal/AsignacionHorario/HorarioAdd.vue')
    }
})
export default class AsistenciaCuadrillaView extends Vue
{

    //#region VUEX
    @State('horarioEmpleados',{namespace:'laboresForestal'}) horarios!:Array<CuadrillaHorario>;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Mutation('setHorarioDeleteItem',{namespace:'laboresForestal'}) updateHorarioEstado!:Function;
    //#endregion

    //#region Variables de las tablas
    header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Fecha",sortable:false,value:'fecha'},
            {text:"Cuadrilla",sortable:false,value:'cuadrillaName'},
            {text:"Estado",sortable:false,value:'estadoProceso'}  ,
            {text:"Action",sortable:false,value:'actions'}                    
    ];
    //#endregion
    //#region Metodos Locales
    abrilModal()
    {
        this.componenteName = "horarioAdd";
        this.modal= true;
        
    }
    limpiar()
    {
        this.modal= false;
        this.componenteName = "";
    }
    //#endregion
    //#region EventosConexion
    async registrarAsistencia(item:CuadrillaHorario)
    {
        try
        {
            this.changeLoading(new Loading(true,"Registrando Asistencia"));
            var rsp = await RegistrarHorario(item);
            if(rsp.isSuccess === true)
            {
                this.updateHorarioEstado(item);
                this.changeAlerta(new Alerta(rsp.isMessage,true,"primary",3000));
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
            }
        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
    //#endregion

    //#region Variables
    modal:boolean=false;
    componenteName:string ="";
    listado:Array<CuadrillaHorario>=[];
    //#endregion
}
