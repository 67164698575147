import { Save } from "@/Generico/Conexion";
import { secondAxios } from "@/plugins/axios";

export class CuadrillaHorario
{
    cuadrillaId!:number;
    cuadrillaName?:string;
    fecha!:string;
    estadoProceso!:string;
    registrado!:boolean;
    detalle:Array<CuadrillaHorarioDetalle> =[];
}

export class CuadrillaHorarioDetalle
{
    empleadoId?:number;
    empleadoName?:string;
    asistio?:boolean;

    constructor(_empleadoId?:number,_empleadoName?:string,asistio?:boolean)
    {
        this.empleadoId  = _empleadoId;
        this.empleadoName = _empleadoName;
        this.asistio = asistio;

    }
}

export async function RegistrarHorario(cuadrilla:CuadrillaHorario)
{
    return Save<CuadrillaHorario>('labores/registrarasistencia',cuadrilla,secondAxios);
}